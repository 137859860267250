//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cropperAvatar from '../cropperAvatar/index.vue';
import vModelMixins from '../../mixins/vModelMixins';

export default {
  name: 'add-avatar',
  mixins: [vModelMixins],
  components: { cropperAvatar: cropperAvatar },
  data: function data() {
    return {
      visible1: false,
      file: null };

  },
  methods: {
    handleInput: function handleInput(file, imgBlob) {
      console.log(file);
      console.log(imgBlob);
      this.$emit('input', imgBlob);
      this.file = file;
    } } };