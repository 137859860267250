var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-avatar" },
    [
      _c(
        "el-button",
        {
          staticClass: "color-darkblue",
          attrs: { type: "primary", size: "mini" },
          on: {
            click: function($event) {
              _vm.visible1 = true
            }
          }
        },
        [_vm._v("设置")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "设置头像",
            width: "500px",
            visible: _vm.visible1
          },
          on: {
            "update:visible": function($event) {
              _vm.visible1 = $event
            }
          }
        },
        [
          _c("cropperAvatar", {
            attrs: { value: _vm.form },
            on: {
              cancle: function($event) {
                _vm.visible1 = false
              },
              input: _vm.handleInput
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }